<template>
  <div>
    <b-card border-variant="danger" v-if="errore_caricamento">
      <b-row class="match-height">
        <b-col
          md="8"
        >
          <div v-if="this.errore_messaggio == ''">
            <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
            <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
          </div>
          <div v-else>
            <h4 class="mt-0 mb-0 text-danger">{{ this.errore_messaggio }}</h4>
          </div>
        </b-col>
        <b-col 
          md="4"
          class="text-center"
        >
          <b-button
          variant="primary"
          :to="{ name: 'area-clienti-dashboard' }"
          >
              <feather-icon
                  icon="CornerUpLeftIcon"
                  size="16"
                  class="mr-1"
              />Torna alla Dashboard
          </b-button>
        </b-col>
      </b-row>
        
    </b-card>
    
    <div class="text-center my-3" v-if="Caricato == false">
        <b-spinner
            variant="primary"
            style="width: 3rem; height: 3rem;"
            class="mr-1"
            label="loading"
        />
    </div>

    <div v-else>
        <h2 class="mb-2">Licenza n. {{ this.dati.licenseData.ref_code }}</h2>
        
        <form-wizard
          ref="myWizard"
          color="#7367F0"
          :title="null"
          :subtitle="null"
          :hide-buttons="true"
          shape="square"
          :start-index="tabIniziale"
          :prevent-cross-tab="true"
        >
          
          <tab-content title="DATI AZIENDALI"></tab-content>

          <tab-content title="CONTRATTO"></tab-content>
          
          <tab-content title="PAGAMENTO"></tab-content>


          <tab-content
            title="DONWLOAD PLICO"
          >
            <div>
              <p>Grazie per aver scelto AgentScout,<br />Il suo pagamento è in fase di elaborazione, sarà confermato a breve.</p><p>Di seguito puoi scaricare il contratto</p>
              <p v-if="btn_scaricare">
                <a :href="link_download_pdf" target="_blank" class="btn btn-primary mt-1 mb-2">Scarica plico contratto</a>
              </p>
            </div>

          </tab-content>
      
        </form-wizard>



    </div>

      
  </div>
</template>

<script>
import router from '@/router'
import { BTabs, BTab, BRow, BCol, BCard, BCardTitle, BCardHeader, BCardBody, BCardText, BSpinner, BButton, BSidebar, VBToggle, BBadge, BForm, BFormGroup, BFormCheckbox, BFormInput, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard, 
    BCardTitle, 
    BCardHeader, 
    BCardBody, 
    BCardText, 
    BSpinner, 
    BButton,
    BSidebar, 
    VBToggle, 
    BBadge,
    BForm, 
    BFormGroup, 
    BFormCheckbox, 
    BFormInput, 
    VBTooltip, 

    FormWizard,
    TabContent,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      Caricato: false,
      errore_caricamento: false,
      id_licenza: router.currentRoute.params.id_riga,
      dati: [],
      stato_licenza: 'da_accettare',
      errore_messaggio: '',

      Caricato_testi_contratto: false,
      errore_testi_contratto: false,

      isSubscription: false,

      tabIniziale: 3,
      
      length,
      
      action_press_otp: false,
      action_press: false,
      action_press_donwload_plico: false,

      btn_verifica: true,
      btn_scaricare: false,
      link_download_pdf: '',
    }
  },
  created() {

    this.$http.get('api/customer/license/card/'+this.id_licenza).then(response => { 
        /*  
        console.log(response)
        console.log("stato esterno -> "+response.status)
        console.log("stato interno -> "+response.data.statusCode)
        */

        if(response.status===200){
          //ok dati ricevuti
          // check autorizzazione ad accedere ai dati oppure no
          if(response.data.statusCode===200){
            //ok autorizzato ! => proseguo
            this.dati = response.data.reply

            console.log("FINE --------------------")
            console.log(this.dati)

                
            //Licenza da ATTIVA (id = 30) -> Pagata 
            // n.b.: FACCIAMO FINTA CHE SIA GIA' PAGATA PERCHE' IL PAGAMENTO AVVIENE CON RITARDO SU STRIPE - ES. ADDEBITO SEPA !!
            this.stato_licenza = 'gia_pagato'

            /* forzo il passaggio al quarto step => RIEPILOGO */
            this.tabIniziale = 3;

            /* recupero link per scaricare contratto plcio pdf in modo sicuro */
            this.scaricaPDFplico()

            this.Caricato = true;


            
          } else {
            if(response.data.statusCode===403){
              //accesso ad una licenza che non è del cliente loggato, furbetto beccato!
              this.errore_messaggio = "Operazione non autorizzata! stai cercando di accedere ad una licenza non di tua pertinenza."
            }
            
            this.Caricato = false;
            this.errore_caricamento = true;
          }
        } else {
          console.log("qui ------------------")
          if(response.status===401){
            //accesso "Unauthenticated" -> accade quando si sviene sloggati dall'api
            console.error("Errore api -> "+response.data.reply['message'])

            if(logoutGeneral() === true){
              // Redirect to login page
              //this.$router.push({ name: 'auth-login' })
              this.$router.replace('/login?status=again')
            }
          }

          this.Caricato = false;
          this.errore_caricamento = true;
        }
      
      }).catch(e => {
        /*
        this.Caricato = false;
        this.errore_caricamento = true;
        */

        console.log("Errore api ......")
        console.log(e)

        this.Caricato = false;
        this.errore_caricamento = true;

      });

  },
  methods: {
    scaricaPDFplico(){
      this.action_press_donwload_plico = true;

      this.$http.post('/api/customer/license/genera-codice-plico/'+this.id_licenza).then(response => { 
        //console.log(response)

        if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
          //console.log(response.data.reply.dominio+'/download-contratto/pdf/'+response.data.reply.codice);

          //redirect verso nuova finestra per il download
          //window.location.href = response.data.reply.dominio+'/download-contratto/pdf/'+response.data.reply.codice;

          //apro nuova tab per scaricare direttamente il file pdf (ma ha il problema del blocco finestra popup)
          //window.open(response.data.reply.dominio+'/download-contratto/pdf/'+response.data.reply.codice, '_blank');

          //gestione del download via click per evitare qualsiasi problema di apertura di finestre per il download
          this.link_download_pdf = response.data.reply.dominio+'/download-contratto/pdf/'+response.data.reply.codice;
          this.btn_verifica = false;
          this.action_press_donwload_plico = false;
          this.btn_scaricare = true;

        } else {
          this.action_press_donwload_plico = false;

          //qualsiasi altro tipo di errore
          this.$swal({
            icon: 'error',
            title: 'Si è verificato un errore',
            text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema - codice errore DWN 4',
            customClass: {
              confirmButton: 'btn btn-outline-primary',
            },
          })
        }

      }).catch(e => {
        this.action_press_donwload_plico = false;
        console.log(e);
      });
    },
    
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-wizard.scss';

.wizard-header{
  display: none;
}

/* disabilità il ckick sui titoli del wizzard */
.vue-form-wizard .wizard-nav-pills a, .vue-form-wizard .wizard-nav-pills li{
  cursor: not-allowed;
  pointer-events: none;
}

.vue-form-wizard .wizard-navigation .wizard-nav li .wizard-icon-circle .wizard-icon-container {
  background-color: #009688 !important;
}
.vue-form-wizard .wizard-navigation .wizard-nav li a .active {
  color: #009688 !important;
}
</style>
